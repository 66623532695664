import { Link } from "react-router-dom";
import style from './style.module.scss';

function InfoContratar({ formulario }) {
  return (
    <>
      <p className={style.planos__info}>
        Após realizar o pagamento, preencha os dados iniciais para a contratação do serviço 
        <a href={formulario} target="_blank" rel="noreferrer noopener"> nesse formulário</a>.
      </p>

      <p className={style.planos__info}>
        Em até 3 dias, entraremos em contato para registrar seu domínio e liberar o acesso ao sistema, ou para configurar o serviço em sua plataforma de hospedagem, no caso de aquisição do mesmo. Se preferir, entre em contato através do WhatsApp <a href="https://api.whatsapp.com/send?phone=5511964914546" target="_blank" rel="noreferrer noopener">(11) 96491-4546</a> ou pelo email <a href="mailto:contato@sistemasflex.com.br" target="_blank" rel="noreferrer noopener"> contato@sistemasflex.com.br</a>, e estaremos à disposição para esclarecer possíveis dúvidas.
      </p>

      <p>
        Confira os <Link to={'/termos-de-uso'}>termos de uso</Link> para a contratação do serviço.
      </p>
    </>
  );
}

export default InfoContratar;