import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InfoContratar from "components/InfoContratar";
import style from "./style.module.scss";
import { Link, NavLink } from "react-router-dom";
import SwipeableTextMobileStepper from 'components/Carousel';

const useStyles = makeStyles((theme) => ({
  title: {
    textDecoration: "none",
    color: "inherit",
  },
  list: {
    width: "auto",
    [theme.breakpoints.down("mobile")]: {
      width: 250,
    },
  },
  active: {
    background: "rgba(0,0,0,0.2)",
  },
  nav: {
    color: "#fff",
    textDecoration: "none",
    margin: "0 14px",
    padding: "12px 18px",
    borderRadius: "5px",
    "&:hover": {
      background: "rgba(0,0,0,0.2)",
    },
    [theme.breakpoints.down("mobile")]: {
      color: "#000",
      background: "none",
      width: "214px",
      display: "block",
      textAlign: "center",
      margin: "0",
      borderBottom: "1px solid #ddd",
      position: "relative",
      top: "-6px",
    },
  },
}));

function Page({ pageData }) {
  const classes = useStyles();

  return (
    <main className={style.main}>
      <Typography variant="h1" className={classes.title}>
        <h1
          style={{
            fontWeight: "600",
          }}
        >
          {pageData[0].titlePage}
        </h1>
      </Typography>

      <section
        className={style.descricao}
        dangerouslySetInnerHTML={{ __html: pageData[0].description }}
      ></section>

      {pageData[0].video && !pageData[0].carousel && (
        <video width="560" height="315" controls="controls">
          <source src={pageData[0].video} type="video/mp4" />
          Seu navegador não suporta o elemento de vídeo.
        </video>
      )}

      {!pageData[0].video && pageData[0].carousel && (
        <SwipeableTextMobileStepper imagesCarousel={pageData[0].imagesCarousel} width='100%' />
      )}

      <section className={style.planos}>
        {pageData[0].planosValues && (
          <>
            <Typography variant="h6" className={classes.title}>
              {pageData[0].filter !== "landing-page" ? <h2>Planos</h2> : null}
            </Typography>

            <ul>
              {pageData[0].planosValues.map((item, index) => {
                return (
                  <li
                    className={`${
                      pageData[0].filter === "landing-page"
                        ? style.landingPage
                        : ""
                    }`}
                  >
                    <div className={style.planos__etiqueta}>{item.type}</div>
                    <div className={style.planos__preco}>{item.value}</div>
                    {
                      pageData[0].filter !== "landing-page" ? <p>{item.info}</p> : <br/>
                    }
                    {item.priceSale && (
                      <p>ou {item.priceSale} para aquisição</p>
                    )}

                    {/* <!-- INICIO FORMULARIO BOTAO PAGBANK: NAO EDITE OS COMANDOS DAS LINHAS ABAIXO --> */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.form,
                      }}
                    />
                    {/* <!-- FINAL FORMULARIO BOTAO PAGBANK --> */}

                    {pageData[0].filter !== "landing-page" && index < 3 ? (
                      <p>Assinar via Pix:</p>
                    ) : null}

                    {item.linkPix !== '' && index < 3 && 
                      <> 
                      <NavLink to={item.linkPix} className={style.planos__linkPix} target="_blank">
                        PIX
                      </NavLink>
                      </>
                    }

                    {index === 3 ?
                      (
                        <NavLink to={item.linkPix} className={style.planos__linkPixAquisicao} target="_blank">
                          Link de pagamento
                        </NavLink>
                      ) : (
                          <p>{pageData[0].keyPix}</p>
                      )  
                    }
                    
                  </li>
                );
              })}
            </ul>
          </>
        )}

        <InfoContratar formulario={pageData[0].formulario} />
      </section>
    </main>
  );
}

export default Page;
