import { useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import { LogoWrap, MenuDesk, StyledLink, StyledMenuItem } from './styles';
import useScreenSize from 'hooks/useScreenSize';
import theme from 'global/theme';

const useStyles = makeStyles((theme) => ({
  mainColor: theme.palette.primary.main,
  drop: {
    textDecoration: 'none',
  },
  title: {
    textDecoration: "none",
    color: "inherit",
  },
  list: {
    width: 'auto',
    [theme.breakpoints.down("mobile")]: {
      width: 250,
    },
  },
  active: {
    background: "rgba(0,0,0,0.2)",
  },
  nav: {
    color: "#fff",
    textDecoration: "none",
    margin: "0 14px",
    padding: "8px 12px",
    borderRadius: "5px",
    [theme.breakpoints.down("mobile")]: {
      color: "#000",
      background: "none",
      width: "214px",
      display: "block",
      textAlign: "center",
      margin: "0",
      borderBottom: "1px solid #ddd",
      position: "relative",
      top: "-6px",
    },
  },
}));

function Header() {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { screenSize } = useScreenSize({ breakpoint: 1024 });

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { text: "Sistemas", path: "/sistemas", name: "sistemas" },
    { text: "Lojas Virtuais", path: "/lojas-virtuais", name: "lojas-virtuais" },
  ];

  const dropdownItems = [
    { text: "Landing Page", path: "/landing-page", name: "landing-page" },
    { text: "Site Institucional", path: "/sites-institucionais", name: "site-institucional" },
    // { text: "Site Portfólio", path: "/portfolio", name: "portfolio" },
    // { text: "Blog", path: "/blog", name: "blog" },
    // { text: "Aplicativos Web", path: "/aplicativos-web", name: "aplicativos-web" },
    // { text: "Otimização de SEO", path: "/seo", name: "seo" },
    // { text: "Desenvolvimento de APIs", path: "/apis", name: "apis" },
    // { text: "Manutenção de Sites", path: "/manutencao-sites", name: "manutencao-sites" },
    // { text: "Desenvolvimento de Software Personalizado", path: "/software-personalizado", name: "software-personalizado" }
  ];

  const list = (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <NavLink
            key='servicos'
            to='/'
            className={({ isActive }) =>
              isActive ? `${classes.active} ${classes.nav}` : `${classes.nav}`
            }
          >
            Serviços
        </NavLink>
        <NavLink
          to="#"
          onClick={handleMenuClick}
          className={classes.nav}
        >
          Sites
        </NavLink>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {dropdownItems.map((item) => (
            <StyledMenuItem key={item.name} onClick={handleMenuClose} sx={{ background: '#ccc', margin: '8px 14px', borderRadius: '3px'}}>
              <StyledLink to={item.path}>
                {item.text}
              </StyledLink>
            </StyledMenuItem>
          ))}
        </Menu>
        {menuItems.map((item) => (
          <NavLink
            key={item.name}
            to={item.path}
            className={({ isActive }) =>
              isActive ? `${classes.active} ${classes.nav}` : `${classes.nav}`
            }
          >
            {item.text}
          </NavLink>
        ))}
        
      </List>
    </div>
  );


  //MOBILE
   const menuItemsMobile = [
    { text: "Sistemas", path: "/sistemas", name: "sistemas" },
    { text: "Lojas Virtuais", path: "/lojas-virtuais", name: "lojas-virtuais" },
    { text: "Landing Page", path: "/landing-page", name: "landing-page" },
    { text: "Site Institucional", path: "/sites-institucionais", name: "site-institucional" },
  ];

  const listMobile = (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuItemsMobile.map((item) => (
          <NavLink
            key={item.name}
            to={item.path}
            className={({ isActive }) =>
              isActive ? `${classes.active} ${classes.nav}` : `${classes.nav}`
            }
          >
            {item.text}
          </NavLink>
        ))}
        
      </List>
    </div>
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            className={classes.title}
            component={Link}
            to={"/"}
            name="/projetos"
          >
            <LogoWrap>
              <img src="/favicon.png" alt="Sistemas Flex" />
              <h1
                style={{
                  fontSize: "inherit",
                  fontFamily: "inherit",
                  fontWeight: "500",
                }}
              >
                Sistemas Flex
              </h1>
            </LogoWrap>
          </Typography>
          {screenSize.width > 1024 ? (
            <MenuDesk>{list}</MenuDesk>
          ) : (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {listMobile}
      </Drawer>
    </>
  );
}

export default Header;