import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Dialog from "@mui/material/Dialog";
import Wrap from './styles';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SwipeableTextMobileStepper({ imagesCarousel }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [openZoom, setOpenZoom] = React.useState(false); // Estado para controlar o modal de zoom
  const maxSteps = imagesCarousel.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleClickOpenZoom = () => {
    setOpenZoom(true);
  };

  const handleCloseZoom = () => {
    setOpenZoom(false);
  };

  return (
    <Wrap>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: 'center',
          height: 20,
          pl: 2,
          py: 2,
          bgcolor: "rgba(0,0,0,0.3)",
          borderRadius: '3px 3px 0 0',
        }}
      >
        <Typography sx={{ color: '#fff' }}>
          {imagesCarousel[activeStep].label}
        </Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {imagesCarousel.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                className='image-carousel'
                sx={{
                  height: 380,
                  display: "block",
                  maxWidth: '1024px',
                  overflow: "hidden",
                  width: "100%",
                  cursor: "pointer",
                }}
                src={step.imgPath}
                alt={step.label}
                onClick={handleClickOpenZoom}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Avançar
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Voltar
          </Button>
        }
      />

      {/* Modal para o zoom */}
      <Dialog open={openZoom} onClose={handleCloseZoom} maxWidth="100vw">
        <Box
          component="img"
          sx={{
            width: "100%",
            height: "auto",
          }}
          src={imagesCarousel[activeStep].imgPath}
          alt={imagesCarousel[activeStep].label}
        />
      </Dialog>
    </Wrap>
  );
}

export default SwipeableTextMobileStepper;
