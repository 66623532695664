import { Facebook, Instagram } from '@mui/icons-material'
import styles from './styles.module.scss'
import { NavLink } from 'react-router-dom'

const depoimentos = [
  {
    title: "Nunc lacinia ante nunc ac lobortis ipsum. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus.",
    img: "/assets/testemunha-01.jpg",
    name: "Jane Doe",
    cargo: "CEO - Facebook",
  },
  {
    title: "Nunc lacinia ante nunc ac lobortis ipsum. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus.",
    img: "/assets/testemunha-02.jpg",
    name: "Jonh Marsh",
    cargo: "CEO - Facebook",
  },
  {
    title: "Nunc lacinia ante nunc ac lobortis ipsum. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus.",
    img: "/assets/testemunha-03.jpg",
    name: "Jane Doe",
    cargo: "CEO - Facebook",
  }
]

const sectionTop = [
  {
    tag: "Lanchas",
    img: "/assets/destino-1.jpg",
    title: "Figueira da Fox",
    description: "Portugal",
  },
  {
    tag: "Lanchas",
    img: "/assets/destino-2.jpg",
    title: "Figueira da Fox",
    description: "Portugal",
  },
  {
    tag: "Lanchas",
    img: "/assets/destino-3.jpg",
    title: "Figueira da Fox",
    description: "Portugal",
  },
  {
    tag: "Lanchas",
    img: "/assets/destino-4.jpg",
    title: "Figueira da Fox",
    description: "Portugal",
  },
    {
    tag: "Lanchas",
    img: "/assets/destino-5.jpg",
    title: "Figueira da Fox",
    description: "Portugal",
  },
]

const destaques = [
  {
    tag: "Lanchas",
    img: "/assets/destaque-1.jpg",
    title: "Figueira da Fox",
    description: "Portugal",
  },
  {
    tag: "Lanchas",
    img: "/assets/destaque-2.jpg",
    title: "Figueira da Fox",
    description: "Portugal",
  },
  {
    tag: "Lanchas",
    img: "/assets/destaque-3.jpg",
    title: "Figueira da Fox",
    description: "Portugal",
  },
  {
    tag: "Lanchas",
    img: "/assets/destaque-4.jpg",
    title: "Figueira da Fox",
    description: "Portugal",
  },
]


export default function LP3() {
  return (
    <div className={styles.lp3}>    
      <section className={styles.section}>
        <img src="/assets/bg-search.jpg" alt="" className={styles.imageTop} />
        <div className={`${styles['search-box']} ${styles['bg-fixed']}`}>
          <header>
            <h1>Lorem Ipsum placeholder</h1>
            <p className={styles.tagline}>Commonly used in the graphic</p>
          </header>
        </div>

        <div className={styles.estatisticas}>
          {[
            { value: '2,500', label: 'Lorem' },
            { value: '10,000', label: 'Ipsum' },
            { value: '1,500', label: 'dolor' },
            { value: '15,000', label: 'sit amet' }
          ].map((stat, index) => (
            <div key={index} className={styles['estatisticas-item']}>
              <span>{stat.value}</span>
              <p>{stat.label}</p>
            </div>
          ))}
        </div>
      </section>

      <section id="barcos" className={styles.section}>
        <div className={styles.content}>
          <header className={styles['section-header']}>
            <h2>Lorem ipsum dolor sit amet</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
          </header>

          <div className={styles.row}>
            {sectionTop.map((i, index) => (
              <div key={index} className={styles['destinos-col']}>
                <div className={styles.box}>
                  <div className={styles['box-name']}>{i.tag}</div>
                  <div className={styles['box-cover']}>
                    <img src={i.img} alt={i.title} />
                  </div>
                  <div className={styles['box-detalhes']}>
                    <div className={styles['box-detalhes-info']}>
                      <h3>{i.title}</h3>
                      <p>{i.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <div className={`${styles.parallax} ${styles['bg-fixed']}`} style={{ backgroundImage: `url('/assets/bg-paralax.jpg')` }}>
        <div className={styles.content}>
          <h2>ENCONTROU O BARCO PREFERIDO?</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing</p>
          <NavLink href="#" target='_blank'>
            Saiba Mais
          </NavLink>
        </div>
      </div>

      <section id="destaques" className={styles.section}>
        <div className={styles.content}>
          <header className={styles['section-header']}>
            <h2>Destaques</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p>
          </header>

          <div className={styles.row}>
            {destaques.map((i,index) => (
              <div key={index} className={styles['destaques-col']}>
                <div className={styles.box}>
                  <div className={styles['box-name']}>{i.tag}</div>
                  <div className={styles['box-cover']}>
                    <img src={i.img} alt={i.title} />
                  </div>
                  <div className={styles['box-detalhes']}>
                    <div className={styles['box-detalhes-info']}>
                      <h3>{i.title}</h3>
                      <p>{i.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="testemunhas" className={`${styles.section} ${styles.testemunhas} ${styles['bg-fixed']}`}>
        <div className={styles.content}>
          <header className={styles['section-header']}>
            <h2>Depoimentos</h2>
            <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>
          </header>

          {depoimentos.map((person, index) => (
            <article key={index} className={styles['testemunhas-article']}>
              <h3>{person.title}</h3>
              <br />
              <img className={styles.image} src={person.img} alt={person.name} />
              <p>
                <span className={styles.name}>{person.name}</span>
                <span>{person.cargo}</span>
              </p>
            </article>
          ))}
        </div>
      </section>
        
      <footer className={`${styles.footer} ${styles.section}`}>
        <div className={styles.content}>
          <div className={styles['footer-col']}>
            <h2>Sobre a Empresa</h2>
            <p>Nunc lacinia ante nunc ac lobortis. Interdum adipiscing porttitor sem non mi integer non faucibus ornare mi ut ante amet placerat aliquet. Volutpat eu sed ante lacinia sapien lorem accumsan varius montes viverra nibh in adipiscing. Lorem ipsum dolor vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing sed feugiat eu faucibus. Integer ac sed amet praesent. Nunc lacinia ante nunc.</p>
          </div>

          <div className={styles['footer-col']}>
            <h2>Social</h2>
                <ul className={styles['footer-col-social']}  style={{'display': 'flex', 'gap': '14px'}}>
                  <li>
                    <NavLink href="#" aria-label="WhatsApp" title="WhatsApp" target='_blank'>
                      <img src="/assets/whatsapp.svg" alt="" width={28} height={28} style={{'filter': 'invert(1)'}} />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink href="#" aria-label="Facebook" title="Facebook" target='_blank'>
                      <Facebook style={{'fontSize': '28px'}} />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink href="#" aria-label="Instagram" title="Instagram" target='_blank'>
                      <Instagram style={{'fontSize': '28px'}}/>
                    </NavLink>
                  </li>
                </ul>
          </div>
          
          <div className={`${styles.row} ${styles['text-center']} ${styles['footer-autorais']}`}>
            Interdum adipiscing porttitor
          </div>
        </div>
      </footer>
    </div>
  )
}