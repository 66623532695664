import { MenuItem } from '@mui/material';
import { NavLink } from 'react-router-dom';
import styled from "styled-components";

export const MenuDesk = styled.nav`
  ul {
    display: flex;

    a {
      text-align: center;
    }
  }
`;

export const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    width: 20px;
    filter: invert(1);
  }
`

export const StyledLink = styled(NavLink)`
  color: #112758;
  text-decoration: none;
`

export const StyledMenuItem = styled(MenuItem)`
  &:hover{
    background-color: #ccc;
    outline: 1px solid #112758;
  }
`