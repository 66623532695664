import { Container, Typography } from "@mui/material";
import ListCards from "components/ListCards";
import { Title } from "../../global/style";


function LandingPagesList() {
  return (
    <>
      <Container>
        <Title>Nossas Landing Pages</Title>
        <Typography variant="body3" color="text.secondary">
          <h3
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              fontWeight: "500",
              padding: "0 14px",
              lineHeight: "24px",
              height: "auto",
              textAlign: "center",
              maxWidth: "1024px",
              margin: "0 auto 22px",
            }}
          >
            Maximize suas conversões com uma Landing Page otimizada!
            Nosso serviço de criação de Landing Pages personalizadas destaca os pontos fortes do seu negócio e atrai seu público-alvo. Trabalhamos conforme um protótipo ou de acordo com a forma que você desejar, garantindo um design exclusivo e uma estrutura focada em resultados. Assim, ajudamos sua marca a se destacar e a atingir seus objetivos de marketing. Seja para captar leads, lançar um produto ou promover um evento, nossa Landing Page é a ferramenta ideal para impulsionar seus resultados online.
          </h3>
        </Typography>
        <ListCards filter="landing-page" />
      </Container>
    </>
  );
}

export default LandingPagesList;