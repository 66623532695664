import Header from './components/Header';
import Footer from './components/Footer';
import Router from './routes';
import { useLocation } from 'react-router-dom';


function App() {
  const location = useLocation();

  return (
    <div className="App">
      {!location.pathname.includes('/lp/') && <Header />}
      <Router />
      {!location.pathname.includes('/lp/') && <Footer />}
    </div>
  );
}

export default App;
