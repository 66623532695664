import { Route, Routes } from 'react-router-dom';

import Home from 'pages/Home';
import AgendarAqui from 'pages/Sistemas/AgendarAqui';
import CamisetasMix from 'pages/LojasVirtuais/CamisetasMix';
import ShopTecnologia from 'pages/LojasVirtuais/ShopTecnologia';
import TermosDeUso from 'pages/TermosUso';
import Planos from 'pages/Planos';
import Sistemas from 'pages/Sistemas';
import LojasVirtuais from 'pages/LojasVirtuais';
import LP1 from 'pages/LandingPagesList/LandingPage/1';
import LP1Page from 'pages/LandingPagesList/LP1';
import LandingPagesList from 'pages/LandingPagesList';
import LP2Page from 'pages/LandingPagesList/LP2';
import PoliticaDePrivacidade from 'pages/PoliticaPrivacidade';
import SobreNos from 'pages/SobreNos';
import SitesInstitucionais from 'pages/SitesInstitucionais';
import InstitucionalSite01 from 'pages/SitesInstitucionais/InstitucionalSite01';
import LP3Page from 'pages/LandingPagesList/LP3';
import LP3 from 'pages/LandingPagesList/LandingPage/2';
import LP4Page from 'pages/LandingPagesList/LP4';

function Router() {
  return (
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route path="/sistemas" element={<Sistemas />} />
      <Route path="/sites-institucionais/" element={<SitesInstitucionais />} />
      <Route path="/sites-institucionais/:id" element={<InstitucionalSite01 />} />
      <Route path="/lojas-virtuais" element={<LojasVirtuais />} />
      <Route path="/landing-page" element={<LandingPagesList />} />
      <Route path="/sistema-agendar-aqui" element={<AgendarAqui />} />
      <Route path="/camisetas-mix" element={<CamisetasMix />} />
      <Route path="/shop-tecnologia" element={<ShopTecnologia />} />
      <Route path="/termos-de-uso" element={<TermosDeUso />} />
      <Route path="/sobre-nos" element={<SobreNos />} />
      <Route path="//politica-de-privacidade" element={<PoliticaDePrivacidade />} />
      <Route path="/planos-ativos-c145135764415" element={<Planos />} />
      <Route path="/lp/1" element={<LP1 />} />
      <Route path="/lp/3" element={<LP3 />} />
      {/* <Route path="/lp/4" element={<LP4Page />} /> */}
      <Route path="/lp-page/1" element={<LP1Page />} />
      <Route path="/lp-page/2" element={<LP2Page />} />
      <Route path="/lp-page/3" element={<LP3Page />} />
      <Route path="/lp-page/4" element={<LP4Page />} />
    </Routes>
  )
}

export default Router;