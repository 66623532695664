import { Box } from '@mui/material';
import styled from 'styled-components';

const Wrap = styled(Box)`
  max-width: 1024px;
  flex-grow: 1;

  .image-carousel{
    @media (max-width: 1023px){
      height: 180px;
    }
  }
`;

export default Wrap;
