import Card from "components/Card";
import style from "components/ListCards/listCard.module.scss";

export const getList = () => {
  let list = [
    {
      productId: "#STI01",
      titlePage: "#STI01 - Site Institucional com Gerenciador de Conteúdo (CMS)",
      titleCard: "Site Institucional com Gerenciador de Conteúdo (CMS)",
      description: `
        <p>
          Potencialize sua presença online com nossos sites institucionais! Oferecemos soluções personalizadas via SaaS que se adaptam às necessidades do seu negócio. Com designs modernos e responsivos, garantimos que sua instituição se destaque na web. Nossa plataforma intuitiva permite atualizações fáceis. Assine agora e transforme sua comunicação digital!
        </p>
        <p><strong>Recursos do Painel de Gerenciamento de Conteúdo:</strong></p>
        <ul>
          <li><strong>Gerenciamento de Itens e Seções:</strong> Controle total sobre o que é exibido no seu site.</li>
          <li><strong>Personalização de Cores:</strong> Defina a cor principal que representará sua marca.</li>
          <li><strong>Gestão de Logo e Banners:</strong> Atualize facilmente sua identidade visual.</li>
          <li><strong>Banners do Carrossel:</strong> Crie carrosséis atrativos com legendas personalizáveis.</li>
          <li><strong>Seção Portfólio:</strong> Destaque seus projetos e conquistas.</li>
          <li><strong>Seção Serviços:</strong> Apresente seus serviços de forma clara e atraente.</li>
          <li><strong>Seção Empresa:</strong> Apresente a missão, visão e valores da sua instituição.</li>
          <li><strong>Seção Equipe:</strong> Apresente sua equipe e suas competências.</li>
          <li><strong>Seção Clientes:</strong> Mostre quem confia no seu trabalho.</li>
          <li><strong>Seção Blog:</strong> Compartilhe conteúdo relevante e atraia visitantes.</li>
          <li><strong>Seção Contato:</strong> Facilite a comunicação com seus usuários.</li>
          <li><strong>Seção Mapa:</strong> Ajude seus clientes a encontrarem você facilmente.</li>
        </ul>
        <p>Além disso, você receberá um <strong>manual do usuário</strong>, que ensinará como gerenciar o painel de gerenciamento de conteúdo de forma prática e eficiente.</p>
      `,
      exibirFidelidade: true,
      planosValues: [
        {
          type: "Mensal",
          value: "R$ 59,90",
          valueInstallments: "",
          form: `
          <!-- INICIO FORMULARIO BOTAO PAGBANK: NAO EDITE OS COMANDOS DAS LINHAS ABAIXO -->
<form action="https://pagseguro.uol.com.br/pre-approvals/request.html" method="post">
<input type="hidden" name="code" value="C44B8B42C1C144CFF4760FB155400D08" />
<input type="hidden" name="iot" value="button" />
<input type="image" src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/99x61-assinar-azul-assina.gif" name="submit" alt="Pague com PagBank - É rápido, grátis e seguro!" width="99" height="61" />
</form>
<!-- FINAL FORMULARIO BOTAO PAGBANK -->
        `,
          linkPix: "https://pag.ae/7-X5WeoVr",
          info: "Acesso pelo período de 1 mês, sem fidelização",
        },
        {
          type: "Semestral",
          value: "6x de R$ 56,90",
          valueInstallments: "ou 6 vezes de R$ 56,90",
          form: `
           <!-- INICIO FORMULARIO BOTAO PAGBANK: NAO EDITE OS COMANDOS DAS LINHAS ABAIXO -->
<form action="https://pagseguro.uol.com.br/pre-approvals/request.html" method="post">
<input type="hidden" name="code" value="1758499A606076B3344B6F8F4896980E" />
<input type="hidden" name="iot" value="button" />
<input type="image" src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/99x61-assinar-azul-assina.gif" name="submit" alt="Pague com PagBank - É rápido, grátis e seguro!" width="99" height="61" />
</form>
<!-- FINAL FORMULARIO BOTAO PAGBANK -->
        `,
          linkPix: "https://pag.ae/7-X61o4hR",
          info: "Acesso por 6 meses com 5% de desconto",
        },
        {
          type: "Anual",
          value: "12x de R$ 53,90",
          valueInstallments: "ou 12 vezes de R$ 53,90",
          form: `
           <!-- INICIO FORMULARIO BOTAO PAGBANK: NAO EDITE OS COMANDOS DAS LINHAS ABAIXO -->
<form action="https://pagseguro.uol.com.br/pre-approvals/request.html" method="post">
<input type="hidden" name="code" value="99178520B5B54E9334F22F85DECCF7F3" />
<input type="hidden" name="iot" value="button" />
<input type="image" src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/99x61-assinar-azul-assina.gif" name="submit" alt="Pague com PagBank - É rápido, grátis e seguro!" width="99" height="61" />
</form>
<!-- FINAL FORMULARIO BOTAO PAGBANK -->
          `,
          linkPix: "https://pag.ae/7-X63yFzp",
          info: "Acesso por 6 meses com 10% de desconto",
        },
        {
          type: "Aquisição",
          value: "R$ 3.500,00",
          valueInstallments: "em até 12x",
          form: `
           
          `,
          linkPix: "https://pag.ae/7-X6iCAB7",
          info: "Compra e configuração em sua plataforma de hospedagem",
        },
      ],
      image: "/sites-institucional/01/site-institucional-01.jpg",
      video: "",
      link: "https://yellow-baboon-220244.hostingersite.com/",
      keyPix: "Chave CNPJ: 37810374000182",
      github: "https://github.com/Front-LucasRibeiro/institucional-site-01",
      formulario: "https://docs.google.com/forms/d/e/1FAIpQLSfYNz_yNpAvmZhGy6fpxtL4hgTnakswmkehJ-AaTw9l5wZDXQ/viewform?usp=sf_link",
      tags: ["Wordpress", "MySql", "PHP"],
      price: "R$ 59,90/mês",
      priceSale: "R$ 3.500,00",
      page: "/sites-institucionais/01",
      linkPix: "",
      filter: "site-institucional",
      carousel: true,
      imagesCarousel: [
        {
          label: "Gerenciamento de Menu",
          imgPath:
            "/sites-institucional/01/menu.jpg",
        },
        {
          label: "Gerenciamento de Blogs",
          imgPath:
            "/sites-institucional/01/blogs.jpg",
        },
         {
          label: "Gerenciamento de Portfólio",
          imgPath:
            "/sites-institucional/01/portfolio.jpg",
        },
        {
          label: "Gerenciamento de Seções",
          imgPath:
            "/sites-institucional/01/gerenciamento-secoes.jpg",
        },
         {
          label: "Gerenciamento de Clientes",
          imgPath:
            "/sites-institucional/01/clientes.jpg",
        },
      ],
    },
    {
      productId: "#S01",
      titlePage: "#S01 - Sistema Agendar Aqui",
      titleCard: "Sistema de agendamentos de serviços",
      description: `
        <p>
          O sistema de cadastro de agendamentos, oferece a funcionalidade de
          fazer o cadastro do agendamento de seu cliente ou o próprio cliente
          realizar o seu agendamento, cada cliente terá uma conta para poder
          conferir os seus agendamentos e o lojista terá uma conta para
          conferir todos agendamentos em cada dia de trabalho.
        </p>
        <p>
          O lojista poderá definir dias e horários de trabalho para cada
          funcionário e os serviços prestados pelo funcionário, assim como
          toda a edição de dados dos funcionários no sistema.
        </p>
        <p>
          No login de administrador do sistema é possível alterar todos os
          dados do sistema e cores do tema, cabeçalho e rodapé, sistema
          totalmente administrável.
        </p>
        <p>
          Veja o vídeo abaixo com a demostração do funcionamento do sistema:
        </p>
      `,
      exibirFidelidade: true,
      planosValues: [
        {
          type: "Mensal",
          value: "R$ 59,90",
          valueInstallments: "",
          form: `
         <!-- INICIO FORMULARIO BOTAO PAGBANK: NAO EDITE OS COMANDOS DAS LINHAS ABAIXO -->
<form action="https://pagseguro.uol.com.br/pre-approvals/request.html" method="post">
<input type="hidden" name="code" value="EE5E78B56060F20444524F970394C431" />
<input type="hidden" name="iot" value="button" />
<input type="image" src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/99x61-assinar-azul-assina.gif" name="submit" alt="Pague com PagBank - É rápido, grátis e seguro!" width="99" height="61" />
</form>
<!-- FINAL FORMULARIO BOTAO PAGBANK -->
        `,
          linkPix: "https://pag.ae/7-SVL6-M1",
          info: "Acesso ao sistema sem fidelização",
        },
        {
          type: "Semestral",
          value: "6x de R$ 56,90",
          valueInstallments: "ou 6 vezes de R$ 56,90",
          form: `
           <!-- INICIO FORMULARIO BOTAO PAGBANK: NAO EDITE OS COMANDOS DAS LINHAS ABAIXO -->
<form action="https://pagseguro.uol.com.br/pre-approvals/request.html" method="post">
<input type="hidden" name="code" value="6B09F4498B8B70ACC4631F878601CF71" />
<input type="hidden" name="iot" value="button" />
<input type="image" src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/99x61-assinar-azul-assina.gif" name="submit" alt="Pague com PagBank - É rápido, grátis e seguro!" width="99" height="61" />
</form>
<!-- FINAL FORMULARIO BOTAO PAGBANK -->
        `,
          linkPix: "https://pag.ae/7-SVMSTKu",
          info: "Acesso ao sistema por 6 meses com 5% de desconto",
        },
        {
          type: "Anual",
          value: "12x de R$ 53,90",
          valueInstallments: "ou 12 vezes de R$ 53,90",
          form: `
           <!-- INICIO FORMULARIO BOTAO PAGBANK: NAO EDITE OS COMANDOS DAS LINHAS ABAIXO -->
<form action="https://pagseguro.uol.com.br/pre-approvals/request.html" method="post">
<input type="hidden" name="code" value="06C0CC345D5DBF6004CE6FA55EFF6B40" />
<input type="hidden" name="iot" value="button" />
<input type="image" src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/99x61-assinar-azul-assina.gif" name="submit" alt="Pague com PagBank - É rápido, grátis e seguro!" width="99" height="61" />
</form>
<!-- FINAL FORMULARIO BOTAO PAGBANK -->
        `,
          linkPix: "https://pag.ae/7-SVNp37Q",
          info: "Acesso ao sistema por 6 meses com 10% de desconto",
        },
      ],
      image: "/assets/barbershop2.jpg",
      video: "/assets/apresentacao-agendar-aqui.mp4",
      link: "/landing-page/2/",
      keyPix: "Chave CNPJ: 37810374000182",
      github: "https://github.com/Front-LucasRibeiro/barber-shop",
      formulario:
        "https://docs.google.com/forms/d/e/1FAIpQLSdQ8E8sjsw5rlqAtEx1HUCJKb2NFEnWpifj2sNedk7hha4NaQ/viewform?usp=sf_link",
      tags: ["React", "MySql"],
      price: "R$ 59,90/mês",
      // priceSale: "R$ 5.000,00",
      priceSale: "",
      page: "/sistema-agendar-aqui",
      linkPix: "https://nubank.com.br/cobranca/8Y4Bx7LXMibmyfs",
      filter: "sistemas",
    },
    {
      productId: "#L01",
      titlePage: "#L01 - Loja Virtual",
      description: `
        <p>
        Loja virtual com gerenciamento completo, simplifique o cadastro de produtos e a gestão de pedidos, oferecendo uma experiência fácil e intuitiva para você se concentrar no que realmente importa: vender. Com meios de pagamento já integrados, como PagSeguro, Pix e transferência bancária, suas transações são seguras e rápidas.
        </p>

        <h3>Principais Benefícios:</h3>

        <ul>
          <li>Cadastro Fácil: Adicione novos produtos com apenas alguns cliques.</li>
          <li>Gestão Simplificada: Controle seus pedidos de forma eficiente.</li>
          <li>Pagamentos Integrados: Ofereça aos seus clientes as opções de pagamento mais populares.</li>
        </ul>
      `,
      video: "",
      exibirFidelidade: true,
      planosValues: [
        {
          type: "Mensal",
          value: "R$ 35,00",
          valueInstallments: "",
          form: `
          <!-- INICIO FORMULARIO BOTAO PAGBANK: NAO EDITE OS COMANDOS DAS LINHAS ABAIXO -->
<form action="https://pagseguro.uol.com.br/pre-approvals/request.html" method="post">
<input type="hidden" name="code" value="A934F0F44747FE4444EBBFA1EA65C29E" />
<input type="hidden" name="iot" value="button" />
<input type="image" src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/99x61-assinar-azul-assina.gif" name="submit" alt="Pague com PagBank - É rápido, grátis e seguro!" width="99" height="61" />
</form>
<!-- FINAL FORMULARIO BOTAO PAGBANK -->
        `,
          linkPix: "https://pag.ae/7-SVPFJ-u",
          info: "Acesso ao sistema sem fidelização",
          priceSale: "",
        },
        {
          type: "Semestral",
          value: "6x de R$ 33,25",
          valueInstallments: "ou 6 vezes de R$ 33,25",
          form: `
             <!-- INICIO FORMULARIO BOTAO PAGBANK: NAO EDITE OS COMANDOS DAS LINHAS ABAIXO -->
<form action="https://pagseguro.uol.com.br/pre-approvals/request.html" method="post">
<input type="hidden" name="code" value="3C30AD937C7C5BEEE46F9F9C3ACEC395" />
<input type="hidden" name="iot" value="button" />
<input type="image" src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/99x61-assinar-azul-assina.gif" name="submit" alt="Pague com PagBank - É rápido, grátis e seguro!" width="99" height="61" />
</form>
<!-- FINAL FORMULARIO BOTAO PAGBANK -->
        `,
          linkPix: "https://pag.ae/7-SVQx2cG",
          info: "Acesso ao sistema por 6 meses com 5% de desconto",
          priceSale: "",
        },
        {
          type: "Anual",
          value: "12x de R$ 31,50",
          valueInstallments: "ou 12 vezes de R$ 31,50",
          form: `
           <!-- INICIO FORMULARIO BOTAO PAGBANK: NAO EDITE OS COMANDOS DAS LINHAS ABAIXO -->
<form action="https://pagseguro.uol.com.br/pre-approvals/request.html" method="post">
<input type="hidden" name="code" value="69B4532501014B7114C37F81A4FB506E" />
<input type="hidden" name="iot" value="button" />
<input type="image" src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/99x61-assinar-azul-assina.gif" name="submit" alt="Pague com PagBank - É rápido, grátis e seguro!" width="99" height="61" />
</form>
<!-- FINAL FORMULARIO BOTAO PAGBANK -->
        `,
          linkPix: "https://pag.ae/7-SVQ-cQu",
          info: "Acesso ao sistema por 1 ano com 10% de desconto",
          priceSale: "",
        },
        {
          type: "Aquisição",
          value: "R$ 3.500,00",
          valueInstallments: "em até 12x",
          form: `
           
          `,
          linkPix: "https://pag.ae/7-X7Q3hhN",
          info: "Compra e configuração em sua plataforma de hospedagem",
        },
      ],
      image: "/assets/camisetasmix.jpg",
      formulario:
        "https://docs.google.com/forms/d/e/1FAIpQLSeoWnr6UtrMq4a6Fxt1xEYp2WNpLP8BK-j8HMXMIdb09ffzlw/viewform?usp=sf_link",
      keyPix: "Chave CNPJ: 37810374000182",
      titleCard: "Loja virtual com (CMS) gerenciador de conteúdo",
      link: "http://camisetasmix.com.br/",
      github: "https://github.com/Front-LucasRibeiro/camisetas-mix",
      tags: ["WooCommerce", "WordPress"],
      price: "R$ 35,00/mês",
      priceSale: "R$ 3.500,00",
      page: "/camisetas-mix",
      filter: "lojas-virtuais",
      carousel: true,
      imagesCarousel: [
        {
          label: "Configurações do Produto - Listagem",
          imgPath:
            "/loja-virtual/configuracoes-produto.png",
        },
        {
          label: "Tela de configurações do produto",
          imgPath:
            "/loja-virtual/tela-produtos.png",
        },
        {
          label: "Tela de categorias do produto",
          imgPath:
            "/loja-virtual/tela-categorias-produto.png",
        },
        {
          label: "Tela de pedidos",
          imgPath:
            "/loja-virtual/tela-pedidos.png",
        },
        {
          label: "Tela de detalhes do pedido",
          imgPath:
            "/loja-virtual/detalhes-pedido.png",
        },
        {
          label: "Tela de configurações gerais",
          imgPath:
            "/loja-virtual/tela-configuracoes-geral.png",
        },
        {
          label: "Tela de configurações gerais",
          imgPath:
            "/loja-virtual/tela-configuracoes-geral.png",
        },
        {
          label: "Tela de clientes",
          imgPath:
            "/loja-virtual/tela-clientes.png",
        },
        {
          label: "Tela de configurações de meios de pagamento",
          imgPath:
            "/loja-virtual/tela-pagamentos.png",
        },
        {
          label: "Tela de relatórios",
          imgPath:
            "/loja-virtual/tela-relatorios.png",
        },
        {
          label: "Tela de formulários",
          imgPath:
            "/loja-virtual/formulario-newsletter.png",
        },
        {
          label: "Email newsletter",
          imgPath:
            "/loja-virtual/email-newsletter.png",
        },
      ],
    },
    {
      productId: "#L02",
      titlePage: "#L02 - Loja Virtual",
      titleCard: "Loja virtual com (CMS) gerenciador de conteúdo",
      exibirFidelidade: true,
      description: `
    <p>
     Loja virtual com gerenciamento completo, simplifique o cadastro de produtos e a gestão de pedidos, oferecendo uma experiência fácil e intuitiva para você se concentrar no que realmente importa: vender. Com meios de pagamento já integrados, como PagSeguro, Pix e transferência bancária, suas transações são seguras e rápidas.
    </p>

    <h3>Principais Benefícios:</h3>

    <ul>
      <li>Cadastro Fácil: Adicione novos produtos com apenas alguns cliques.</li>
      <li>Gestão Simplificada: Controle seus pedidos de forma eficiente.</li>
      <li>Pagamentos Integrados: Ofereça aos seus clientes as opções de pagamento mais populares.</li>
    </ul>
  `,
      video: "",
      planosValues: [
        {
          type: "Mensal",
          value: "R$ 35,00",
          valueInstallments: "",
          form: `
          <!-- INICIO FORMULARIO BOTAO PAGBANK: NAO EDITE OS COMANDOS DAS LINHAS ABAIXO -->
<form action="https://pagseguro.uol.com.br/pre-approvals/request.html" method="post">
<input type="hidden" name="code" value="A934F0F44747FE4444EBBFA1EA65C29E" />
<input type="hidden" name="iot" value="button" />
<input type="image" src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/99x61-assinar-azul-assina.gif" name="submit" alt="Pague com PagBank - É rápido, grátis e seguro!" width="99" height="61" />
</form>
<!-- FINAL FORMULARIO BOTAO PAGBANK -->
        `,
          linkPix: "https://pag.ae/7-SVPFJ-u",
          info: "Acesso ao sistema sem fidelização",
          priceSale: "",
        },
        {
          type: "Semestral",
          value: "6x de R$ 33,25",
          valueInstallments: "ou 6 vezes de R$ 33,25",
          form: `
             <!-- INICIO FORMULARIO BOTAO PAGBANK: NAO EDITE OS COMANDOS DAS LINHAS ABAIXO -->
<form action="https://pagseguro.uol.com.br/pre-approvals/request.html" method="post">
<input type="hidden" name="code" value="3C30AD937C7C5BEEE46F9F9C3ACEC395" />
<input type="hidden" name="iot" value="button" />
<input type="image" src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/99x61-assinar-azul-assina.gif" name="submit" alt="Pague com PagBank - É rápido, grátis e seguro!" width="99" height="61" />
</form>
<!-- FINAL FORMULARIO BOTAO PAGBANK -->
        `,
          linkPix: "https://pag.ae/7-SVQx2cG",
          info: "Acesso ao sistema por 6 meses com 5% de desconto",
          priceSale: "",
        },
        {
          type: "Anual",
          value: "12x de R$ 31,50",
          valueInstallments: "ou 12 vezes de R$ 31,50",
          form: `
           <!-- INICIO FORMULARIO BOTAO PAGBANK: NAO EDITE OS COMANDOS DAS LINHAS ABAIXO -->
<form action="https://pagseguro.uol.com.br/pre-approvals/request.html" method="post">
<input type="hidden" name="code" value="69B4532501014B7114C37F81A4FB506E" />
<input type="hidden" name="iot" value="button" />
<input type="image" src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/99x61-assinar-azul-assina.gif" name="submit" alt="Pague com PagBank - É rápido, grátis e seguro!" width="99" height="61" />
</form>
<!-- FINAL FORMULARIO BOTAO PAGBANK -->
        `,
          linkPix: "https://pag.ae/7-SVQ-cQu",
          info: "Acesso ao sistema por 1 ano com 10% de desconto",
          priceSale: "",
        },
        {
          type: "Aquisição",
          value: "R$ 3.500,00",
          valueInstallments: "em até 12x",
          form: `
           
          `,
          linkPix: "https://pag.ae/7-X7Q3hhN",
          info: "Compra e configuração em sua plataforma de hospedagem",
        },
      ],
      keyPix: "Chave CNPJ: 37810374000182",
      formulario:
        "https://docs.google.com/forms/d/e/1FAIpQLSeoWnr6UtrMq4a6Fxt1xEYp2WNpLP8BK-j8HMXMIdb09ffzlw/viewform?usp=sf_link",
      image: "/assets/shoptecnologia.jpg",
      link: "https://shoptecnologia.com.br/",
      github:
        "https://github.com/Front-LucasRibeiro/shoptecLoja virtual com gerenciador de conteúdonologia",
      tags: ["WooCommerce", "WordPress"],
      price: "R$ 35,00/mês",
      priceSale: "R$ 3.500,00",
      page: "/shop-tecnologia",
      filter: "lojas-virtuais",
      carousel: true,
      imagesCarousel: [
        {
          label: "Configurações do Produto - Listagem",
          imgPath:
            "/loja-virtual/configuracoes-produto.png",
        },
        {
          label: "Tela de configurações do produto",
          imgPath:
            "/loja-virtual/tela-produtos.png",
        },
        {
          label: "Tela de categorias do produto",
          imgPath:
            "/loja-virtual/tela-categorias-produto.png",
        },
        {
          label: "Tela de pedidos",
          imgPath:
            "/loja-virtual/tela-pedidos.png",
        },
        {
          label: "Tela de detalhes do pedido",
          imgPath:
            "/loja-virtual/detalhes-pedido.png",
        },
        {
          label: "Tela de configurações gerais",
          imgPath:
            "/loja-virtual/tela-configuracoes-geral.png",
        },
        {
          label: "Tela de configurações gerais",
          imgPath:
            "/loja-virtual/tela-configuracoes-geral.png",
        },
        {
          label: "Tela de clientes",
          imgPath:
            "/loja-virtual/tela-clientes.png",
        },
        {
          label: "Tela de configurações de meios de pagamento",
          imgPath:
            "/loja-virtual/tela-pagamentos.png",
        },
        {
          label: "Tela de relatórios",
          imgPath:
            "/loja-virtual/tela-relatorios.png",
        },
        {
          label: "Tela de formulários",
          imgPath:
            "/loja-virtual/formulario-newsletter.png",
        },
        {
          label: "Email newsletter",
          imgPath:
            "/loja-virtual/email-newsletter.png",
        },
      ],
    },
    {
      productId: "#LP01",
      exibirFidelidade: false,
      image: "/assets/lp1.png",
      titlePage: "#LP01 - Landing Page",
      titleCard: "Implantação de Landing Page com conteúdo",
      description: `
        <p>Implante uma landing page no seu site existente ou em um novo endereço web <br/> por apenas <strong>R$ 320,00</strong>.</p>
        <p>Confira o modelo de landing page e personalize-o de acordo com seu produto ou serviço.</p>
        <a href="/lp/1" class="${style.btnDescription}" target="_blank">Veja o modelo</a>
      `,
      planosValues: [
        {
          type: "Preço único",
          value: "R$ 320,00",
          valueInstallments: "",
          priceSale: "",
          form: `
          <!-- INICIO DO BOTAO PAGBANK --><a href="https://pag.ae/7-SVubsVu/button" target="_blank" title="Pagar com PagBank"><img src="//assets.pagseguro.com.br/ps-integration-assets/botoes/pagamentos/95x45-pagar-azul.gif" alt="Pague com PagBank - é rápido, grátis e seguro!" /></a><!-- FIM DO BOTAO PAGBANK -->
          `,
          linkPix: "",
          info: "",
        },
      ],
      video: "",
      github: "",
      link: "/lp/1",
      tags: [],
      price: "R$ 320,00",
      priceSale: "",
      page: "/lp-page/1",
      keyPix: "Chave PIX CNPJ: 37810374000182",
      filter: "landing-page",
      formulario:
        "https://docs.google.com/forms/d/e/1FAIpQLSd4Hc4dTgzeJhNLbXjEB--ULPt97YETGQF4Kv-TMeuTa6iCMg/viewform?usp=sf_link",
    },
    {
      productId: "#LP02",
      exibirFidelidade: false,
      image: "/assets/lp-2.png",
      titlePage: "#LP02 - Landing Page",
      titleCard: "Implantação de Landing Page com conteúdo",
      description: `
        <p>Implante uma landing page no seu site existente ou em um novo endereço web <br/> por apenas <strong>R$ 320,00</strong>.</p>
        <p>Confira o modelo de landing page e personalize-o de acordo com seu produto ou serviço.</p>
        <a href="/landing-page/2/" class="${style.btnDescription}" target="_blank">Veja o modelo</a>
      `,
      planosValues: [
        {
          type: "Preço único",
          value: "R$ 320,00",
          valueInstallments: "",
          priceSale: "",
          form: `
          <!-- INICIO DO BOTAO PAGBANK --><a href="https://pag.ae/7-SVubsVu/button" target="_blank" title="Pagar com PagBank"><img src="//assets.pagseguro.com.br/ps-integration-assets/botoes/pagamentos/95x45-pagar-azul.gif" alt="Pague com PagBank - é rápido, grátis e seguro!" /></a><!-- FIM DO BOTAO PAGBANK -->
          `,
          linkPix: "",
          info: "",
        },
      ],
      video: "",
      github: "",
      link: "/landing-page/2/",
      tags: [],
      price: "R$ 320,00",
      priceSale: "",
      page: "/lp-page/2",
      keyPix: "Chave PIX CNPJ: 37810374000182",
      filter: "landing-page",
      formulario:
        "https://docs.google.com/forms/d/e/1FAIpQLSd4Hc4dTgzeJhNLbXjEB--ULPt97YETGQF4Kv-TMeuTa6iCMg/viewform?usp=sf_link",
    },
    {
      productId: "#LP03",
      exibirFidelidade: false,
      image: "/assets/lp3.jpg",
      titlePage: "#LP03 - Landing Page",
      titleCard: "Implantação de Landing Page com conteúdo",
      description: `
        <p>Implante uma landing page no seu site existente ou em um novo endereço web <br/> por apenas <strong>R$ 320,00</strong>.</p>
        <p>Confira o modelo de landing page e personalize-o de acordo com seu produto ou serviço.</p>
        <a href="/lp/3" class="${style.btnDescription}" target="_blank">Veja o modelo</a>
      `,
      planosValues: [
        {
          type: "Preço único",
          value: "R$ 320,00",
          valueInstallments: "",
          priceSale: "",
          form: `
          <!-- INICIO DO BOTAO PAGBANK --><a href="https://pag.ae/7-SVubsVu/button" target="_blank" title="Pagar com PagBank"><img src="//assets.pagseguro.com.br/ps-integration-assets/botoes/pagamentos/95x45-pagar-azul.gif" alt="Pague com PagBank - é rápido, grátis e seguro!" /></a><!-- FIM DO BOTAO PAGBANK -->
          `,
          linkPix: "",
          info: "",
        },
      ],
      video: "",
      github: "",
      link: "/lp/3",
      tags: [],
      price: "R$ 320,00",
      priceSale: "",
      page: "/lp-page/3",
      keyPix: "Chave PIX CNPJ: 37810374000182",
      filter: "landing-page",
      formulario:
        "https://docs.google.com/forms/d/e/1FAIpQLSd4Hc4dTgzeJhNLbXjEB--ULPt97YETGQF4Kv-TMeuTa6iCMg/viewform?usp=sf_link",
    },
    {
      productId: "#LP04",
      exibirFidelidade: false,
      image: "/assets/lp4.jpg",
      titlePage: "#LP04 - Landing Page",
      titleCard: "Implantação de Landing Page com conteúdo",
      description: `
        <p>Implante uma landing page no seu site existente ou em um novo endereço web <br/> por apenas <strong>R$ 320,00</strong>.</p>
        <p>Confira o modelo de landing page e personalize-o de acordo com seu produto ou serviço.</p>
        <a href="/landing-page/4/" class="${style.btnDescription}" target="_blank">Veja o modelo</a>
      `,
      planosValues: [
        {
          type: "Preço único",
          value: "R$ 320,00",
          valueInstallments: "",
          priceSale: "",
          form: `
          <!-- INICIO DO BOTAO PAGBANK --><a href="https://pag.ae/7-SVubsVu/button" target="_blank" title="Pagar com PagBank"><img src="//assets.pagseguro.com.br/ps-integration-assets/botoes/pagamentos/95x45-pagar-azul.gif" alt="Pague com PagBank - é rápido, grátis e seguro!" /></a><!-- FIM DO BOTAO PAGBANK -->
          `,
          linkPix: "",
          info: "",
        },
      ],
      video: "",
      github: "",
      link: "/landing-page/4/",
      tags: [],
      price: "R$ 320,00",
      priceSale: "",
      page: "/lp-page/4",
      keyPix: "Chave PIX CNPJ: 37810374000182",
      filter: "landing-page",
      formulario:
        "https://docs.google.com/forms/d/e/1FAIpQLSd4Hc4dTgzeJhNLbXjEB--ULPt97YETGQF4Kv-TMeuTa6iCMg/viewform?usp=sf_link",
    },
  ];

  return list;
};

const ListCards = ({ filter }) => {
  return (
    <ul className={style.listCard}>
      {getList().map((projeto, index) => {
        if (filter === projeto.filter) {
          return <Card projeto={projeto} index={index} key={index} />;
        } else if (filter === 'home') {
          return <Card projeto={projeto} index={index} key={index} />;
        }
      })}
    </ul>
  );
};

export default ListCards;
