import { Container, Typography } from "@mui/material";
import ListCards from "components/ListCards";
import { Title } from "../../global/style";


function SitesInstitucionais() {
  return (
    <>
      <Container>
        <Title>Nossos Sites Institucionais</Title>
        <Typography variant="body3" color="text.secondary">
          <h3
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              fontWeight: "500",
              padding: "0 14px",
              lineHeight: "24px",
              height: "auto",
              textAlign: "center",
              maxWidth: "1024px",
              margin: "0 auto 22px",
            }}
          >
            Potencialize sua presença online com nossos sites institucionais! Oferecemos soluções personalizadas via SaaS que se adaptam às necessidades do seu negócio. Com designs modernos e responsivos, garantimos que sua instituição se destaque na web. Nossa plataforma intuitiva permite atualizações fáceis, e nossa equipe de suporte está sempre pronta para ajudar. Assine agora e transforme sua comunicação digital!
          </h3>
        </Typography>
        <ListCards filter="site-institucional" />
      </Container>
    </>
  );
}

export default SitesInstitucionais;