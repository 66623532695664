import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
  },
  breakpoints: {
    values: {
      xs: 0, // Extra small screens
      sm: 600, // Small screens
      md: 900, // Medium screens
      lg: 1200, // Large screens
      xl: 1536, // Extra large screens
      mobile: 1025,
    },
  },
});

export default theme;
